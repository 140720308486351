import type { AppData } from '../../viewer.app';
import model from './model';

interface DefaultPlugin {
  set resourceId(postId: string);
}

export default model.createController(({ $w, flowAPI, appData: _appData }) => {
  const appData = _appData as AppData;

  return {
    pageReady: async () => {
      const ui = {
        root: $w('#relatedPostsRoot') as $w.Box,
        slotContainer: $w(
          '#page-bottom-1',
        ) as $w.SlotsPlaceholder<DefaultPlugin>,
      };

      await appData.subjects.postPageRenderModel.subscribe((data) => {
        if (ui.slotContainer.slot) {
          ui.slotContainer.slot.resourceId = data.post.id;
        }
      });
    },
    exports: {},
  };
});
